import type { ThemeDefinition } from "@Themes";
import type { SpacerSizes } from "@Tokens";

export const getGap = (
	itemsInRow: number,
	gap: keyof SpacerSizes | 0,
	theme: ThemeDefinition,
) => {
	const gapNumber =
		typeof gap === "string"
			? Number.parseInt(String(theme.space[gap]), 10)
			: gap;
	const pixels = Math.ceil(gapNumber - gapNumber / itemsInRow);

	return pixels;
};
