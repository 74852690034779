import type { ThemeDefinition } from "@Themes";
import type { SpacerSizes } from "@Tokens";
import { getGap } from "./getGap";

export const getFlexBasis =
	(itemsInRow: number, gap: keyof SpacerSizes | 0) =>
	(theme: ThemeDefinition) => {
		const pixels = getGap(itemsInRow, gap, theme);

		return `calc(100% / ${itemsInRow} - ${pixels}px)`;
	};
