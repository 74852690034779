import type { SxStyleProp } from "theme-ui";

/**
 * CSS styles to create an ellipsis effect on overflowing text.
 */
export const ellipsis: SxStyleProp = {
	overflowX: "hidden",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
};
