import type React from "react";

import {
	GoogleMapsProvider,
	type GoogleMapsProviderProps,
} from "./GoogleMapsProvider";
import {
	HelmetComponentProvider,
	type HelmetComponentValue,
} from "./HelmetComponentProvider";
import {
	PublicPathProvider,
	type PublicPathProviderProps,
} from "./PublicPathProvider";
import { RouterLinkProvider, type RouterLinkValue } from "./RouterLinkProvider";
import {
	TrackingProvider,
	type TrackingProviderProps,
} from "./TrackingProvider";
import {
	TranslationProvider,
	type TranslationProviderProps,
} from "./TranslationProvider";
import { WindowSizeProvider } from "./WindowSizeProvider";

export type DesignSystemProviderProps = HelmetComponentValue &
	PublicPathProviderProps &
	RouterLinkValue &
	TrackingProviderProps &
	TranslationProviderProps &
	GoogleMapsProviderProps;

export const DesignSystemProvider: React.FC<
	React.PropsWithChildren<DesignSystemProviderProps>
> = ({
	children,
	publicPath,
	sendEvent,
	HelmetComponent,
	Link,
	historyPush,
	useHref,
	locale,
	translations,
	translationNamespace,
	googleMapsApiKey,
}) => (
	<PublicPathProvider publicPath={publicPath}>
		<TrackingProvider sendEvent={sendEvent}>
			<HelmetComponentProvider helmetComponent={HelmetComponent}>
				<GoogleMapsProvider googleMapsApiKey={googleMapsApiKey}>
					<RouterLinkProvider
						Link={Link}
						historyPush={historyPush}
						useHref={useHref}
					>
						<TranslationProvider
							locale={locale}
							translations={translations}
							translationNamespace={translationNamespace}
						>
							<WindowSizeProvider>{children}</WindowSizeProvider>
						</TranslationProvider>
					</RouterLinkProvider>
				</GoogleMapsProvider>
			</HelmetComponentProvider>
		</TrackingProvider>
	</PublicPathProvider>
);
