type Prop = Record<string, any>;

export const getPropPermutations = (propList: Prop) => {
	const result = [] as Prop[];
	const keys = Object.keys(propList);
	const max = keys.length - 1;

	const combine = (a: Prop, idx: number) => {
		const propName = keys[idx];
		propList[propName].forEach((prop: Prop) => {
			const b = { ...a };
			b[propName] = prop;

			if (idx === max) {
				result.push(b);
			} else {
				combine(b, idx + 1);
			}
		});
	};
	combine({}, 0);

	return result;
};
