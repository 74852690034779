import {
	TranslationProvider as PhrasebookProvider,
	// eslint-disable-next-line no-restricted-imports
	useTranslation as usePhrasebookTranslation,
} from "@loveholidays/phrasebook";
import type { TranslationData } from "@loveholidays/phrasebook";
import type React from "react";
import { createContext, useContext } from "react";

const TranslationNamespaceContext = createContext("");

export interface TranslationProviderProps {
	locale?: string;
	translations?: TranslationData;
	translationNamespace?: string;
}

/**
 * When `locale` and `translations` are provided we create a Phrasebook context with them,
 * otherwise we expect to have a Phrasebook context available in the react tree.
 */
export const TranslationProvider: React.FC<
	React.PropsWithChildren<TranslationProviderProps>
> = ({ locale, translations, translationNamespace = "lvhds", children }) => (
	<TranslationNamespaceContext.Provider value={translationNamespace}>
		{locale && translations ? (
			// @ts-ignore: we need to explicitly type children in PhrasebookProvider component
			<PhrasebookProvider
				locale={locale}
				namespaces={{
					[translationNamespace]: translations,
				}}
			>
				{children}
			</PhrasebookProvider>
		) : (
			children
		)}
	</TranslationNamespaceContext.Provider>
);

export const useTranslation = () => {
	const namespace = useContext(TranslationNamespaceContext);

	return {
		namespace,
		...usePhrasebookTranslation(namespace),
	};
};
