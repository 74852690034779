import type React from "react";
import { createContext, useContext } from "react";

export const GoogleMapsProviderContext = createContext<string | undefined>(
	undefined,
);

export interface GoogleMapsProviderProps {
	googleMapsApiKey?: string;
}

export const GoogleMapsProvider: React.FC<
	React.PropsWithChildren<GoogleMapsProviderProps>
> = ({ googleMapsApiKey, children }) => (
	<GoogleMapsProviderContext.Provider value={googleMapsApiKey}>
		{children}
	</GoogleMapsProviderContext.Provider>
);

export const useGoogleMapsApiKey = () => useContext(GoogleMapsProviderContext);
