import { css } from "@emotion/react";

// Ported from the previously used https://github.com/zacanger/styled-reset/blob/master/src/index.ts
export const reset = css`
  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a,
  abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
  strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form,
  label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas,
  details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby,
  section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, main, menu, nav, section {
    display: block;
  }
  *[hidden] { display: none; }
  body { line-height: 1; }
  ol, ul { list-style: none; }
  blockquote, q { quotes: none; }

  blockquote::before,
  blockquote::after,
  q::before,
  q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  button {
    cursor: pointer;
    border: none;
    
    /* Remove default button styles. */
    background-color: transparent; 
    background-image: none;
    
    text-transform: none; /* Remove the inheritance of text transform in Edge and Firefox. */
    
    /* Change the font styles in all browsers. */
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;

    margin: 0; /* Remove the margin in Firefox and Safari. */
    padding: 0; /* Remove default padding in all browsers. */
    -webkit-appearance: button; /* Correct the inability to style clickable types in iOS and Safari. */
  }
`;
