import type { SxStyleProp } from "theme-ui";

/**
 * Limits a block to a set number of lines of text.
 */
export const lineClamp = (lines: number): SxStyleProp => ({
	textOverflow: "ellipsis",
	overflow: "hidden",
	WebkitLineClamp: lines,
	display: "-webkit-box",
	WebkitBoxOrient: "vertical" as SxStyleProp,
});
