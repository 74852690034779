export function debounce<T extends (...args: any[]) => any>(
	callback: T,
	delay = 100,
) {
	let timerId: ReturnType<typeof setTimeout>;

	return function debounceInner(...args: Parameters<T>): void {
		clearTimeout(timerId);
		timerId = setTimeout(() => {
			callback(...args);
		}, delay);
	};
}
