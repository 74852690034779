import type React from "react";
import { createContext, useContext, useEffect, useState } from "react";

import { debounce } from "../utils/debounce";

const isClient = typeof window !== "undefined";

export const WindowSizeContext = createContext({
	width: Number.POSITIVE_INFINITY,
	height: Number.POSITIVE_INFINITY,
});

export const WindowSizeProvider: React.FC<React.PropsWithChildren<unknown>> = ({
	children,
}) => {
	const [state, setState] = useState(() => ({
		width: isClient ? window.innerWidth : Number.POSITIVE_INFINITY,
		height: isClient ? window.innerHeight : Number.POSITIVE_INFINITY,
	}));

	useEffect(() => {
		const handler = debounce(() => {
			setState({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}, 100);

		window.addEventListener("resize", handler, { passive: true });

		return () => {
			window.removeEventListener("resize", handler);
		};
	}, []);

	return (
		<WindowSizeContext.Provider value={state}>
			{children}
		</WindowSizeContext.Provider>
	);
};

export const useWindowSize = () => useContext(WindowSizeContext);
