import React, { forwardRef, type HTMLAttributes } from "react";
import type { SxStyleProp } from "theme-ui";

import type { ComponentProps } from "@Components";

export const pageContainerWidthOnDesktop = 1280;

export const pageContainerStyles: SxStyleProp = {
	marginX: ({ breakpointSizes }) => [
		breakpointSizes.mobileMargin,
		breakpointSizes.tabletMargin,
		"auto",
	],
	maxWidth: ({ breakpointSizes }) => [
		null,
		null,
		`calc(100% - ${2 * breakpointSizes.laptopMargin}px)`,
	],
	width: [null, null, pageContainerWidthOnDesktop],
};

type PageContainerProps = ComponentProps & HTMLAttributes<HTMLElement>;

export const PageContainer = forwardRef<HTMLDivElement, PageContainerProps>(
	({ as: Wrapper = "div", children, className, ...rest }, ref) => (
		<Wrapper ref={ref} className={className} sx={pageContainerStyles} {...rest}>
			{children}
		</Wrapper>
	),
);
