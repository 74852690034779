export type Responsive<T> = T | [T] | [T, T] | [T, T | null, T];
interface ResponsiveConfig<T extends Record<any, any>> {
	get(variant: keyof T): T[keyof T];
	get(variant: Responsive<keyof T>): {
		[K in keyof T[keyof T]]: Responsive<T[keyof T][K]>;
	};
}
/**
Helper to allow using different variants on different breakpoints.

Many of our components have variants which are a quick way of applying several complementary styles.

We have a syntax to apply different styles at different breakpoints (to be responsive to different screen sizes).
eg `color: ['mobileColour', 'tabletColour', 'desktopColour']`.

This helper can be used by a component to allow us to reuse the syntax to specify different variants on different breakpoints.

see `.spec` and `src/ux/components/Badge/Badge.tsx` for usage examples.
*/

export const createResponsiveConfig = <T extends Record<any, any>>(
	config: T,
): ResponsiveConfig<T> => ({
	get: (variant: any) => {
		if (Array.isArray(variant)) {
			const result = variant
				.map((v) => config[v])
				.reduce(
					(acc, current) => {
						for (const key of Object.keys(current)) {
							acc[key] = (acc[key] || []).concat(current[key]);
						}

						return acc;
					},
					{} as { [K in keyof T[keyof T]]: Responsive<T[keyof T][K]> },
				);

			return result;
		}

		return config[variant];
	},
});
