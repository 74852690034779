import type React from "react";
import { createContext, useCallback, useContext } from "react";

export enum DOMInteractionEvent {
	CLICK = "click",
	KEYUP = "keyup",
	SWIPE = "swipe",
}

export type TrackingEvent =
	| "Product Click"
	| "Product List Viewed"
	| "Product Viewed"
	| "WebEvent"
	| "Map";

interface TrackingObjectBase {
	event: TrackingEvent;
	eventCallback?: () => void;
}

export type ProductTrackingList =
	| "AlternativeFlights"
	| "Availability"
	| "DestinationCollectionCard"
	| "FavouritesResults"
	| "HotelAndFlights"
	| "HotelDetails"
	| "Insurance"
	| "LandingPage"
	| "Luggage"
	| "MapSearch"
	| "Parking"
	| "PaymentFailed"
	| "PaymentOptions"
	| "PaymentResumeRequest"
	| "PaymentStart"
	| "PaymentSuccessful"
	| "PaymentVerificationStart"
	| "PinnedResults"
	| "PopularHolidays"
	| "PopularHotels"
	| "PriorityBoarding"
	| "RecentlyViewedHotels"
	| "RecommendedHotels"
	| "RecommendedHotelsPanda"
	| "ReturnToOffer"
	| "Rooms"
	| "RoomsBoardBasis"
	| "SearchResults"
	| "Seats"
	| "SelectedOffer"
	| "SimilarHotels"
	| "Transfer";

interface EcommerceTrackingObject extends TrackingObjectBase {
	event: "Product Click" | "Product List Viewed" | "Product Viewed";
	payload: {
		masterId?: string | number;
		name?: string;
		position?: number;
		price?: number | string;
		list?: ProductTrackingList;
		searchUrl?: string;
		customUrl?: string;
		refundablePackage?: boolean;
		title?: string;
		image?: {
			description: string;
			url: string;
		};
		description?: string;
	};
}

interface WebEventTrackingObject extends TrackingObjectBase {
	event: "WebEvent";
	payload: {
		category:
			| "click"
			| "keyboard"
			| "swipe"
			| "Subscription"
			| "error"
			| "view";
		action?: string;
		label?: string;
		error?: string;
	};
}

interface MapTrackingObject extends TrackingObjectBase {
	event: "Map";
	payload:
		| {
				action: "loaded";
		  }
		| {
				action: "error";
				error: string;
		  };
}

export type TrackingObject =
	| EcommerceTrackingObject
	| WebEventTrackingObject
	| MapTrackingObject;

interface TrackingContextValue {
	trackEvent: (
		event: DOMInteractionEvent,
		action: string,
		label?: string,
	) => void;

	sendEvent: (obj: TrackingObject) => void;
}

const defaultState: TrackingContextValue = {
	trackEvent: () => undefined,
	sendEvent: () => undefined,
};

export const TrackingProviderContext =
	createContext<TrackingContextValue>(defaultState);

export interface TrackingProviderProps {
	sendEvent: (obj: TrackingObject) => void;
}

export const TrackingProvider: React.FC<
	React.PropsWithChildren<TrackingProviderProps>
> = ({ sendEvent, children }) => {
	const trackEvent: TrackingContextValue["trackEvent"] = useCallback(
		(event, action, label) => {
			const category = (
				{ click: "click", keyup: "keyboard", swipe: "swipe" } as const
			)[event];
			if (category) {
				sendEvent({ event: "WebEvent", payload: { category, action, label } });
			}
		},
		[sendEvent],
	);

	return (
		<TrackingProviderContext.Provider value={{ trackEvent, sendEvent }}>
			{children}
		</TrackingProviderContext.Provider>
	);
};

export const useTrackingContext = () => useContext(TrackingProviderContext);
